// Name:            Tab
// Description:     Component to create a tabbed navigation
//
// Component:       `uk-tab`
//
// Modifiers:       `uk-tab-bottom`
//                  `uk-tab-left`
//                  `uk-tab-right`
//
// States:          `uk-active`
//                  `uk-disabled`
//
// ========================================================================

// Variables
// ========================================================================

@tab-margin-horizontal: 20px;

@tab-item-padding-horizontal: 20px;
@tab-item-padding-vertical: 9px;
@tab-item-color: @global-muted-color;
@tab-item-hover-color: @global-color;
@tab-item-hover-text-decoration: none;
@tab-item-active-color: @global-emphasis-color;
@tab-item-disabled-color: @global-muted-color;

/* ========================================================================
   Component: Tab
 ========================================================================== */

/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */

.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -@tab-margin-horizontal;
  /* 3 */
  padding: 0;
  list-style: none;
  position: relative;
  .hook-tab;
}
.uk-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  border-bottom: 1px solid @global-border;
}
/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */

.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: @tab-margin-horizontal;
  /* 3 */
  position: relative;
}
/* Items
 ========================================================================== */

/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center text if a width is set
 * 2. Style
 */

.uk-tab > * > a {
  /* 1 */
  display: block;
  text-align: center;
  /* 2 */
  padding: @tab-item-padding-vertical @tab-item-padding-horizontal;
  color: @tab-item-color;
  .hook-tab-item;
}
/* Hover + Focus */
.uk-tab > * > a:hover,
.uk-tab > * > a:focus {
  color: @tab-item-hover-color;
  text-decoration: @tab-item-hover-text-decoration;
  .hook-tab-item-hover;
}
/* Active */
.uk-tab > .uk-active > a {
  color: @tab-item-active-color;
  border-color: @global-primary-background;
  .hook-tab-item-active;
}
/* Disabled */
.uk-tab > .uk-disabled > a {
  color: @tab-item-disabled-color;
  .hook-tab-item-disabled;
}
/* Position modifier
 ========================================================================== */

/*
 * Bottom
 */

.uk-tab-bottom {
  .hook-tab-bottom;
}
.uk-tab-bottom > * > a {
  .hook-tab-bottom-item;
}
/*
 * Left + Right
 * 1. Reset Gutter
 */

.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  height: 100%;
  /* 1 */
  margin-left: 0;
}
/* 1 */
.uk-tab-left > *,
.uk-tab-right > * { padding-left: 0; }
.uk-tab-left > * > a {
  text-align: left;
  border-right: 2px solid transparent;
  border-bottom: none;
}
.uk-tab-left::before {
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  border-left: 1px solid @global-border;
  border-bottom: none;
}
.uk-tab-left {
  .hook-tab-left;
}
.uk-tab-right {
  .hook-tab-right;
}
.uk-tab-left > * > a {
  text-align: left;
  .hook-tab-left-item;
}
.uk-tab-right > * > a {
  text-align: left;
  .hook-tab-right-item;
}
// Hooks
// ========================================================================

.hook-tab-misc;

.hook-tab() {}
.hook-tab-item() {}
.hook-tab-item-hover() {}
.hook-tab-item-active() {}
.hook-tab-item-disabled() {}
.hook-tab-bottom() {}
.hook-tab-bottom-item() {}
.hook-tab-left() {}
.hook-tab-left-item() {}
.hook-tab-right() {}
.hook-tab-right-item() {}
.hook-tab-misc() {}
// Inverse
// ========================================================================

@inverse-tab-item-color: @inverse-global-muted-color;
@inverse-tab-item-hover-color: @inverse-global-color;
@inverse-tab-item-active-color: @inverse-global-emphasis-color;
@inverse-tab-item-disabled-color: @inverse-global-muted-color;

.hook-inverse() {

  .uk-tab {
    .hook-inverse-tab;
  }

  .uk-tab > * > a {
    color: @inverse-tab-item-color;
    .hook-inverse-tab-item;
  }

  .uk-tab > * > a:hover,
  .uk-tab > * > a:focus {
    color: @inverse-tab-item-hover-color;
    .hook-inverse-tab-item-hover;
  }

  .uk-tab > .uk-active > a {
    color: @inverse-tab-item-active-color;
    .hook-inverse-tab-item-active;
  }

  .uk-tab > .uk-disabled > a {
    color: @inverse-tab-item-disabled-color;
    .hook-inverse-tab-item-disabled;
  }

}
.hook-inverse-tab() {}
.hook-inverse-tab-item() {}
.hook-inverse-tab-item-hover() {}
.hook-inverse-tab-item-active() {}
.hook-inverse-tab-item-disabled() {}