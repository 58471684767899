// Name:            Search
// Description:     Component to create the search
//
// Component:       `uk-search`
//
// Sub-objects:     `uk-search-input`
//                  `uk-search-toggle`
//
// Adopted:         `uk-search-icon`
//
// Modifier:        `uk-search-default`
//                  `uk-search-navbar`
//                  `uk-search-large`
//
// ========================================================================

// Variables
// ========================================================================

@search-color: @global-color;
@search-placeholder-color: @global-muted-color;

@search-icon-color: @global-color;

@search-default-width: 180px;
@search-default-height: @global-control-height;
@search-default-padding-horizontal: 6px;
@search-default-background: @global-muted-background;
@search-default-focus-background: @search-default-background;

@search-default-icon-width: @global-control-height;

@search-navbar-width: 400px;
@search-navbar-height: 40px;
@search-navbar-background: transparent;
@search-navbar-font-size: @global-large-font-size;

@search-navbar-icon-width: 40px;

@search-large-width: 500px;
@search-large-height: 80px;
@search-large-background: transparent;
@search-large-font-size: @global-xxlarge-font-size;

@search-large-icon-width: 80px;

@search-toggle-color: @global-color;
@search-toggle-hover-color: @global-link-hover-color;

/* ========================================================================
   Component: Search
 ========================================================================== */

/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Reset `form`
 */

.uk-search {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0;
}
/* Input
 ========================================================================== */

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */

.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration { -webkit-appearance: none; }
/*
 * Removes placeholder transparency in Firefox.
 */

.uk-search-input::-moz-placeholder { opacity: 1; }
/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */

.uk-search-input {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: @search-color;
  .hook-search-input;
}
.uk-search-input:focus { outline: none; }
/* Placeholder */
.uk-search-input:-ms-input-placeholder { color: @search-placeholder-color !important; }
.uk-search-input::placeholder { color: @search-placeholder-color; }
/* Icon (Adopts `uk-icon`)
 ========================================================================== */

/*
 * Remove default focus style
 */

.uk-search-icon:focus { outline: none; }
/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */

.uk-search .uk-search-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: @search-icon-color;
}
/*
 * Required for `a`.
 */

.uk-search .uk-search-icon:hover { color: @search-icon-color; }
/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */

.uk-search .uk-search-icon:not(a):not(button):not(input) { pointer-events: none; }
/*
 * Position modifier
 */

.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto;
}
/* Default modifier
 ========================================================================== */

.uk-search-default { width: @search-default-width; }
/*
 * Input
 */

.uk-search-default .uk-search-input {
  height: @search-default-height;
  padding-left: @search-default-padding-horizontal;
  padding-right: @search-default-padding-horizontal;
  background: @search-default-background;
  .hook-search-default-input;
}
/* Focus */
.uk-search-default .uk-search-input:focus {
  background-color: @search-default-focus-background;
  .hook-search-default-input-focus;
}
/*
 * Icon
 */

.uk-search-default .uk-search-icon { width: @search-default-icon-width; }
.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) + .uk-search-input { padding-left: (@search-default-icon-width); }
.uk-search-default .uk-search-icon-flip + .uk-search-input { padding-right: (@search-default-icon-width); }
/* Navbar modifier
 ========================================================================== */

.uk-search-navbar { width: @search-navbar-width; }
/*
 * Input
 */

.uk-search-navbar .uk-search-input {
  height: @search-navbar-height;
  background: @search-navbar-background;
  font-size: @search-navbar-font-size;
  .hook-search-navbar-input;
}
/*
 * Icon
 */

.uk-search-navbar .uk-search-icon { width: @search-navbar-icon-width; }
.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) + .uk-search-input { padding-left: (@search-navbar-icon-width); }
.uk-search-navbar .uk-search-icon-flip + .uk-search-input { padding-right: (@search-navbar-icon-width); }
/* Large modifier
 ========================================================================== */

.uk-search-large { width: @search-large-width; }
/*
 * Input
 */

.uk-search-large .uk-search-input {
  height: @search-large-height;
  background: @search-large-background;
  font-size: @search-large-font-size;
  .hook-search-large-input;
}
/*
 * Icon
 */

.uk-search-large .uk-search-icon { width: @search-large-icon-width; }
.uk-search-large .uk-search-icon:not(.uk-search-icon-flip) + .uk-search-input { padding-left: (@search-large-icon-width); }
.uk-search-large .uk-search-icon-flip + .uk-search-input { padding-right: (@search-large-icon-width); }
/* Toggle
 ========================================================================== */

.uk-search-toggle {
  color: @search-toggle-color;
  .hook-search-toggle;
}
/* Hover + Focus */
.uk-search-toggle:hover,
.uk-search-toggle:focus {
  color: @search-toggle-hover-color;
  .hook-search-toggle-hover;
}
// Hooks
// ========================================================================

.hook-search-misc;

.hook-search-input() {}
.hook-search-default-input() {}
.hook-search-default-input-focus() {}
.hook-search-navbar-input() {}
.hook-search-large-input() {}
.hook-search-toggle() {}
.hook-search-toggle-hover() {}
.hook-search-misc() {}
// Inverse
// ========================================================================

@inverse-search-color: @inverse-global-color;
@inverse-search-placeholder-color: @inverse-global-muted-color;

@inverse-search-icon-color: @inverse-global-muted-color;

@inverse-search-default-background: @inverse-global-muted-background;
@inverse-search-default-focus-background: @inverse-search-default-background;

@inverse-search-navbar-background: transparent;

@inverse-search-large-background: transparent;

@inverse-search-toggle-color: @inverse-global-muted-color;
@inverse-search-toggle-hover-color: @inverse-global-color;

.hook-inverse() {

  //
  // Input
  //

  .uk-search-input { color: @inverse-search-color; }

  .uk-search-input:-ms-input-placeholder { color: @inverse-search-placeholder-color !important; }
  .uk-search-input::placeholder { color: @inverse-search-placeholder-color; }

  //
  // Icon
  //

  .uk-search .uk-search-icon { color: @inverse-search-icon-color; }

  .uk-search .uk-search-icon:hover { color: @inverse-search-icon-color; }

  //
  // Style modifier
  //

  .uk-search-default .uk-search-input {
    background-color: @inverse-search-default-background;
    .hook-inverse-search-default-input;
  }
  .uk-search-default .uk-search-input:focus {
    background-color: @inverse-search-default-background;
    .hook-inverse-search-default-input-focus;
  }

  .uk-search-navbar .uk-search-input {
    background-color: @inverse-search-navbar-background;
    .hook-inverse-search-navbar-input;
  }

  .uk-search-large .uk-search-input {
    background-color: @inverse-search-large-background;
    .hook-inverse-search-large-input;
  }

  //
  // Toggle
  //

  .uk-search-toggle {
    color: @inverse-search-toggle-color;
    .hook-inverse-search-toggle;
  }

  .uk-search-toggle:hover,
  .uk-search-toggle:focus {
    color: @inverse-search-toggle-hover-color;
    .hook-inverse-search-toggle-hover;
  }

}
.hook-inverse-search-default-input() {}
.hook-inverse-search-default-input-focus() {}
.hook-inverse-search-navbar-input() {}
.hook-inverse-search-large-input() {}
.hook-inverse-search-toggle() {}
.hook-inverse-search-toggle-hover() {}
