// Name:            Button
// Description:     Styles for buttons
//
// Component:       `uk-button`
//
// Sub-objects:     `uk-button-group`
//
// Modifiers:       `uk-button-default`
//                  `uk-button-primary`
//                  `uk-button-secondary`
//                  `uk-button-danger`
//                  `uk-button-text`
//                  `uk-button-link`
//                  `uk-button-small`
//                  `uk-button-large`
//
// States:          `uk-active`
//
// ========================================================================

// Variables
// ========================================================================

@button-line-height: @global-control-height;
@button-small-line-height: @global-control-small-height;
@button-large-line-height: @global-control-large-height;

@button-font-size: @global-font-size;
@button-small-font-size: @global-small-font-size;
@button-large-font-size: @global-medium-font-size;

@button-padding-horizontal: @global-small-gutter;
@button-small-padding-horizontal: @global-small-gutter;
@button-large-padding-horizontal: @global-gutter;

@button-default-background: @global-muted-background;
@button-default-color: @global-emphasis-color;
@button-default-hover-background: darken(@button-default-background, 5%);
@button-default-hover-color: @global-emphasis-color;
@button-default-active-background: darken(@button-default-background, 10%);
@button-default-active-color: @global-emphasis-color;

@button-primary-background: @global-primary-background;
@button-primary-color: @global-inverse-color;
@button-primary-hover-background: darken(@button-primary-background, 5%);
@button-primary-hover-color: @global-inverse-color;
@button-primary-active-background: darken(@button-primary-background, 10%);
@button-primary-active-color: @global-inverse-color;

@button-secondary-background: @global-secondary-background;
@button-secondary-color: @global-inverse-color;
@button-secondary-hover-background: darken(@button-secondary-background, 5%);
@button-secondary-hover-color: @global-inverse-color;
@button-secondary-active-background: darken(@button-secondary-background, 10%);
@button-secondary-active-color: @global-inverse-color;

@button-danger-background: @global-danger-background;
@button-danger-color: @global-inverse-color;
@button-danger-hover-background: darken(@button-danger-background, 5%);
@button-danger-hover-color: @global-inverse-color;
@button-danger-active-background: darken(@button-danger-background, 10%);
@button-danger-active-color: @global-inverse-color;

@button-disabled-background: @global-muted-background;
@button-disabled-color: @global-muted-color;

@button-text-line-height: @global-line-height;
@button-text-color: @global-muted-color;
@button-text-hover-color: @global-color;
@button-text-disabled-color: @global-muted-color;

@button-link-line-height: @global-line-height;
@button-link-color: @global-link-color;
@button-link-hover-color: @global-link-hover-color;
@button-link-hover-text-decoration: underline;
@button-link-disabled-color: @global-muted-color;

/* ========================================================================
   Component: Button
 ========================================================================== */

/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Style
 * 8. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 9. Align text if button has a width
 * 10. Required for `a`.
 */

.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: @global-border-radius;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 @button-padding-horizontal;
  vertical-align: middle;
  font-size: @button-font-size;
  /* 8 */
  line-height: @button-line-height;
  /* 9 */
  text-align: center;
  /* 10 */
  text-decoration: none;
  .hook-button;
}
.uk-button:not(:disabled) { cursor: pointer; }
/*
 * Remove the inner border and padding in Firefox.
 */

.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/* Hover */
.uk-button:hover {
  /* 8 */
  text-decoration: none;
  .hook-button-hover;
}
/* Focus */
.uk-button:focus {
  outline: none;
  .hook-button-focus;
}
/* OnClick + Active */
.uk-button:active,
.uk-button.uk-active {
  .hook-button-active;
}
/* Style modifiers
 ========================================================================== */

/*
 * Default
 */

.uk-button-default {
  background-color: @button-default-background;
  color: @button-default-color;
  .hook-button-default;
}
/* Hover + Focus */
.uk-button-default:hover,
.uk-button-default:focus {
  background-color: @button-default-hover-background;
  color: @button-default-hover-color;
  .hook-button-default-hover;
}
/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: @button-default-active-background;
  color: @button-default-active-color;
  .hook-button-default-active;
}
/*
 * Primary
 */

.uk-button-primary {
  background-color: @button-primary-background;
  color: @button-primary-color;
  .hook-button-primary;
}
/* Hover + Focus */
.uk-button-primary:hover,
.uk-button-primary:focus {
  background-color: @button-primary-hover-background;
  color: @button-primary-hover-color;
  .hook-button-primary-hover;
}
/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: @button-primary-active-background;
  color: @button-primary-active-color;
  .hook-button-primary-active;
}
/*
 * Secondary
 */

.uk-button-secondary {
  background-color: @button-secondary-background;
  color: @button-secondary-color;
  .hook-button-secondary;
}
/* Hover + Focus */
.uk-button-secondary:hover,
.uk-button-secondary:focus {
  background-color: @button-secondary-hover-background;
  color: @button-secondary-hover-color;
  .hook-button-secondary-hover;
}
/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: @button-secondary-active-background;
  color: @button-secondary-active-color;
  .hook-button-secondary-active;
}
/*
 * Danger
 */

.uk-button-danger {
  background-color: @button-danger-background;
  color: @button-danger-color;
  .hook-button-danger;
}
/* Hover + Focus */
.uk-button-danger:hover,
.uk-button-danger:focus {
  background-color: @button-danger-hover-background;
  color: @button-danger-hover-color;
  .hook-button-danger-hover;
}
/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: @button-danger-active-background;
  color: @button-danger-active-color;
  .hook-button-danger-active;
}
/*
 * Disabled
 * The same for all style modifiers
 */

.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: @button-disabled-background;
  color: @button-disabled-color;
  .hook-button-disabled;
}
/* Size modifiers
 ========================================================================== */

.uk-button-small {
  padding: 0 @button-small-padding-horizontal;
  line-height: @button-small-line-height;
  font-size: @button-small-font-size;
  .hook-button-small;
}
.uk-button-large {
  padding: 0 @button-large-padding-horizontal;
  line-height: @button-large-line-height;
  font-size: @button-large-font-size;
  .hook-button-large;
}
/* Text modifiers
 ========================================================================== */

/*
 * Text
 * 1. Reset
 * 2. Style
 */

.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: @button-text-line-height;
  background: none;
  /* 2 */
  color: @button-text-color;
  .hook-button-text;
}
/* Hover + Focus */
.uk-button-text:hover,
.uk-button-text:focus {
  color: @button-text-hover-color;
  .hook-button-text-hover;
}
/* Disabled */
.uk-button-text:disabled {
  color: @button-text-disabled-color;
  .hook-button-text-disabled;
}
/*
 * Link
 * 1. Reset
 * 2. Style
 */

.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: @button-link-line-height;
  background: none;
  /* 2 */
  color: @button-link-color;
  .hook-button-link;
}
/* Hover + Focus */
.uk-button-link:hover,
.uk-button-link:focus {
  color: @button-link-hover-color;
  text-decoration: @button-link-hover-text-decoration;
}
/* Disabled */
.uk-button-link:disabled {
  color: @button-link-disabled-color;
  text-decoration: none;
}
/* Group
 ========================================================================== */

/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */

.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative;
}
// Hooks
// ========================================================================

.hook-button-misc;

.hook-button() {}
.hook-button-hover() {}
.hook-button-focus() {}
.hook-button-active() {}
.hook-button-default() {}
.hook-button-default-hover() {}
.hook-button-default-active() {}
.hook-button-primary() {}
.hook-button-primary-hover() {}
.hook-button-primary-active() {}
.hook-button-secondary() {}
.hook-button-secondary-hover() {}
.hook-button-secondary-active() {}
.hook-button-danger() {}
.hook-button-danger-hover() {}
.hook-button-danger-active() {}
.hook-button-disabled() {}
.hook-button-small() {}
.hook-button-large() {}
.hook-button-text() {}
.hook-button-text-hover() {}
.hook-button-text-disabled() {}
.hook-button-link() {}
.hook-button-misc() {}
// Inverse
// ========================================================================

@inverse-button-default-background: @inverse-global-primary-background;
@inverse-button-default-color: @inverse-global-inverse-color;
@inverse-button-default-hover-background: darken(@inverse-button-default-background, 5%);
@inverse-button-default-hover-color: @inverse-global-inverse-color;
@inverse-button-default-active-background: darken(@inverse-button-default-background, 10%);
@inverse-button-default-active-color: @inverse-global-inverse-color;
@inverse-button-primary-background: @inverse-global-primary-background;
@inverse-button-primary-color: @inverse-global-inverse-color;
@inverse-button-primary-hover-background: darken(@inverse-button-primary-background, 5%);
@inverse-button-primary-hover-color: @inverse-global-inverse-color;
@inverse-button-primary-active-background: darken(@inverse-button-primary-background, 10%);
@inverse-button-primary-active-color: @inverse-global-inverse-color;
@inverse-button-secondary-background: @inverse-global-primary-background;
@inverse-button-secondary-color: @inverse-global-inverse-color;
@inverse-button-secondary-hover-background: darken(@inverse-button-secondary-background, 5%);
@inverse-button-secondary-hover-color: @inverse-global-inverse-color;
@inverse-button-secondary-active-background: darken(@inverse-button-secondary-background, 10%);
@inverse-button-secondary-active-color: @inverse-global-inverse-color;
@inverse-button-text-color: @inverse-global-muted-color;
@inverse-button-text-hover-color: @inverse-global-color;
@inverse-button-text-disabled-color: @inverse-global-muted-color;
@inverse-button-link-color: @inverse-global-muted-color;
@inverse-button-link-hover-color: @inverse-global-color;

.hook-inverse() {

  //
  // Default
  //

  .uk-button-default {
    background-color: @inverse-button-default-background;
    color: @inverse-button-default-color;
    .hook-inverse-button-default;
  }

  .uk-button-default:hover,
  .uk-button-default:focus {
    background-color: @inverse-button-default-hover-background;
    color: @inverse-button-default-hover-color;
    .hook-inverse-button-default-hover;
  }

  .uk-button-default:active,
  .uk-button-default.uk-active {
    background-color: @inverse-button-default-active-background;
    color: @inverse-button-default-active-color;
    .hook-inverse-button-default-active;
  }

  //
  // Primary
  //

  .uk-button-primary {
    background-color: @inverse-button-primary-background;
    color: @inverse-button-primary-color;
    .hook-inverse-button-primary;
  }

  .uk-button-primary:hover,
  .uk-button-primary:focus {
    background-color: @inverse-button-primary-hover-background;
    color: @inverse-button-primary-hover-color;
    .hook-inverse-button-primary-hover;
  }

  .uk-button-primary:active,
  .uk-button-primary.uk-active {
    background-color: @inverse-button-primary-active-background;
    color: @inverse-button-primary-active-color;
    .hook-inverse-button-primary-active;
  }

  //
  // Secondary
  //

  .uk-button-secondary {
    background-color: @inverse-button-secondary-background;
    color: @inverse-button-secondary-color;
    .hook-inverse-button-secondary;
  }

  .uk-button-secondary:hover,
  .uk-button-secondary:focus {
    background-color: @inverse-button-secondary-hover-background;
    color: @inverse-button-secondary-hover-color;
    .hook-inverse-button-secondary-hover;
  }

  .uk-button-secondary:active,
  .uk-button-secondary.uk-active {
    background-color: @inverse-button-secondary-active-background;
    color: @inverse-button-secondary-active-color;
    .hook-inverse-button-secondary-active;
  }

  //
  // Text
  //

  .uk-button-text {
    color: @inverse-button-text-color;
    .hook-inverse-button-text;
  }

  .uk-button-text:hover,
  .uk-button-text:focus {
    color: @inverse-button-text-hover-color;
    .hook-inverse-button-text-hover;
  }

  .uk-button-text:disabled {
    color: @inverse-button-text-disabled-color;
    .hook-inverse-button-text-disabled;
  }

  //
  // Link
  //

  .uk-button-link {
    color: @inverse-button-link-color;
    .hook-inverse-button-link;
  }

  .uk-button-link:hover,
  .uk-button-link:focus { color: @inverse-button-link-hover-color; }

}
.hook-inverse-button-default() {}
.hook-inverse-button-default-hover() {}
.hook-inverse-button-default-active() {}
.hook-inverse-button-primary() {}
.hook-inverse-button-primary-hover() {}
.hook-inverse-button-primary-active() {}
.hook-inverse-button-secondary() {}
.hook-inverse-button-secondary-hover() {}
.hook-inverse-button-secondary-active() {}
.hook-inverse-button-text() {}
.hook-inverse-button-text-hover() {}
.hook-inverse-button-text-disabled() {}
.hook-inverse-button-link() {}
