// Name:            Subnav
// Description:     Component to create a sub navigation
//
// Component:       `uk-subnav`
//
// Modifiers:       `uk-subnav-divider`
//                  `uk-subnav-pill`
//
// States:          `uk-active`
//                  `uk-first-column`
//
// ========================================================================

// Variables
// ========================================================================

@subnav-margin-horizontal: 20px;

@subnav-item-color: @global-muted-color;
@subnav-item-hover-color: @global-color;
@subnav-item-hover-text-decoration: none;
@subnav-item-active-color: @global-link-color;

@subnav-divider-margin-horizontal: @subnav-margin-horizontal;
@subnav-divider-border-height: 1.5em;
@subnav-divider-border-width: @global-border-width;
@subnav-divider-border: @global-border;

@subnav-pill-item-padding-vertical: 5px;
@subnav-pill-item-padding-horizontal: 10px;
@subnav-pill-item-background: transparent;
@subnav-pill-item-color: @subnav-item-color;
@subnav-pill-item-hover-background: @global-muted-background;
@subnav-pill-item-hover-color: @global-color;
@subnav-pill-item-onclick-background: @subnav-pill-item-hover-background;
@subnav-pill-item-onclick-color: @subnav-pill-item-hover-color;
@subnav-pill-item-active-background: @global-primary-background;
@subnav-pill-item-active-color: @global-inverse-color;

@subnav-item-disabled-color: @global-muted-color;

/* ========================================================================
   Component: Subnav
 ========================================================================== */

/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */

.uk-subnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -@subnav-margin-horizontal;
  /* 3 */
  padding: 0;
  list-style: none;
  .hook-subnav;
}
/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */

.uk-subnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: @subnav-margin-horizontal;
  /* 3 */
  position: relative;
}
/* Items
 ========================================================================== */

/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * Using `:first-child` instead of `a` to support `span` elements for text
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */

.uk-subnav > * > :first-child {
  /* 1 */
  display: block;
  /* 2 */
  color: @subnav-item-color;
  .hook-subnav-item;
}
/* Hover + Focus */
.uk-subnav > * > a:hover,
.uk-subnav > * > a:focus {
  color: @subnav-item-hover-color;
  text-decoration: @subnav-item-hover-text-decoration;
  outline: none;
  .hook-subnav-item-hover;
}
/* Active */
.uk-subnav > .uk-active > a {
  color: @subnav-item-active-color;
  .hook-subnav-item-active;
}
/* Divider modifier
 ========================================================================== */

/*
 * 1. Align items and divider vertically
 */

.uk-subnav-divider > * {
  /* 1 */
  display: flex;
  align-items: center;
}
/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 */

.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  content: "";
  height: @subnav-divider-border-height;
  margin-left: (@subnav-divider-margin-horizontal - @subnav-margin-horizontal);
  margin-right: @subnav-divider-margin-horizontal;
  border-left: @subnav-divider-border-width solid @subnav-divider-border;
  .hook-subnav-divider;
}
/* Pill modifier
 ========================================================================== */

.uk-subnav-pill > * > :first-child {
  padding: @subnav-pill-item-padding-vertical @subnav-pill-item-padding-horizontal;
  background: @subnav-pill-item-background;
  color: @subnav-pill-item-color;
  .hook-subnav-pill-item;
}
/* Hover + Focus */
.uk-subnav-pill > * > a:hover,
.uk-subnav-pill > * > a:focus {
  background-color: @subnav-pill-item-hover-background;
  color: @subnav-pill-item-hover-color;
  .hook-subnav-pill-item-hover;
}
/* OnClick */
.uk-subnav-pill > * > a:active {
  background-color: @subnav-pill-item-onclick-background;
  color: @subnav-pill-item-onclick-color;
  .hook-subnav-pill-item-onclick;
}
/* Active */
.uk-subnav-pill > .uk-active > a {
  background-color: @subnav-pill-item-active-background;
  color: @subnav-pill-item-active-color;
  .hook-subnav-pill-item-active;
}
/* Disabled
 * The same for all style modifiers
 ========================================================================== */

.uk-subnav > .uk-disabled > a {
  color: @subnav-item-disabled-color;
  .hook-subnav-item-disabled;
}
// Hooks
// ========================================================================

.hook-subnav-misc;

.hook-subnav() {}
.hook-subnav-item() {}
.hook-subnav-item-hover() {}
.hook-subnav-item-active() {}
.hook-subnav-divider() {}
.hook-subnav-pill-item() {}
.hook-subnav-pill-item-hover() {}
.hook-subnav-pill-item-onclick() {}
.hook-subnav-pill-item-active() {}
.hook-subnav-item-disabled() {}
.hook-subnav-misc() {}
// Inverse
// ========================================================================

@inverse-subnav-item-color: @inverse-global-muted-color;
@inverse-subnav-item-hover-color: @inverse-global-color;
@inverse-subnav-item-active-color: @inverse-global-emphasis-color;
@inverse-subnav-divider-border: @inverse-global-border;
@inverse-subnav-pill-item-background: transparent;
@inverse-subnav-pill-item-color: @inverse-global-muted-color;
@inverse-subnav-pill-item-hover-background: @inverse-global-muted-background;
@inverse-subnav-pill-item-hover-color: @inverse-global-color;
@inverse-subnav-pill-item-onclick-background: @inverse-subnav-pill-item-hover-background;
@inverse-subnav-pill-item-onclick-color: @inverse-subnav-pill-item-hover-color;
@inverse-subnav-pill-item-active-background: @inverse-global-primary-background;
@inverse-subnav-pill-item-active-color: @inverse-global-inverse-color;
@inverse-subnav-item-disabled-color: @inverse-global-muted-color;

.hook-inverse() {

  .uk-subnav > * > :first-child {
    color: @inverse-subnav-item-color;
    .hook-inverse-subnav-item;
  }

  .uk-subnav > * > a:hover,
  .uk-subnav > * > a:focus {
    color: @inverse-subnav-item-hover-color;
    .hook-inverse-subnav-item-hover;
  }

  .uk-subnav > .uk-active > a {
    color: @inverse-subnav-item-active-color;
    .hook-inverse-subnav-item-active;
  }

  //
  // Divider
  //

  .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
    border-left-color: @inverse-subnav-divider-border;
    .hook-inverse-subnav-divider;
  }

  //
  // Pill
  //

  .uk-subnav-pill > * > :first-child {
    background-color: @inverse-subnav-pill-item-background;
    color: @inverse-subnav-pill-item-color;
    .hook-inverse-subnav-pill-item;
  }

  .uk-subnav-pill > * > a:hover,
  .uk-subnav-pill > * > a:focus {
    background-color: @inverse-subnav-pill-item-hover-background;
    color: @inverse-subnav-pill-item-hover-color;
    .hook-inverse-subnav-pill-item-hover;
  }

  .uk-subnav-pill > * > a:active {
    background-color: @inverse-subnav-pill-item-onclick-background;
    color: @inverse-subnav-pill-item-onclick-color;
    .hook-inverse-subnav-pill-item-onclick;
  }

  .uk-subnav-pill > .uk-active > a {
    background-color: @inverse-subnav-pill-item-active-background;
    color: @inverse-subnav-pill-item-active-color;
    .hook-inverse-subnav-pill-item-active;
  }

  //
  // Disabled
  //

  .uk-subnav > .uk-disabled > a {
    color: @inverse-subnav-item-disabled-color;
    .hook-inverse-subnav-item-disabled;
  }

}
.hook-inverse-subnav-item() {}
.hook-inverse-subnav-item-hover() {}
.hook-inverse-subnav-item-active() {}
.hook-inverse-subnav-divider() {}
.hook-inverse-subnav-pill-item() {}
.hook-inverse-subnav-pill-item-hover() {}
.hook-inverse-subnav-pill-item-onclick() {}
.hook-inverse-subnav-pill-item-active() {}
.hook-inverse-subnav-item-disabled() {}
