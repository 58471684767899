// Name:            Notification
// Description:     Component to create notification messages
//
// Component:       `uk-notification`
//
// Sub-objects:     `uk-notification-message`
//
// Adopted:         `uk-notification-close`
//
// Modifiers:       `uk-notification-top-center`
//                  `uk-notification-top-right`
//                  `uk-notification-bottom-left`
//                  `uk-notification-bottom-center`
//                  `uk-notification-bottom-right`
//                  `uk-notification-message-primary`
//                  `uk-notification-message-success`
//                  `uk-notification-message-warning`
//                  `uk-notification-message-danger`
//
// ========================================================================

// Variables
// ========================================================================

@notification-position: 10px;
@notification-z-index: @global-z-index + 40;
@notification-width: 350px;

@notification-message-margin-bottom: 10px;
@notification-message-padding: @global-small-gutter;
@notification-message-background: @global-muted-background;
@notification-message-color: @global-color;
@notification-message-font-size: @global-medium-font-size;
@notification-message-line-height: 1.4;

@notification-close-top: @notification-message-padding + 5px;
@notification-close-right: @notification-message-padding;

@notification-message-primary-color: @global-primary-background;
@notification-message-success-color: @global-success-background;
@notification-message-warning-color: @global-warning-background;
@notification-message-danger-color: @global-danger-background;

/* ========================================================================
   Component: Notification
 ========================================================================== */

/*
 * 1. Set position
 * 2. Dimensions
 */

.uk-notification {
  /* 1 */
  position: fixed;
  top: @notification-position;
  left: @notification-position;
  z-index: @notification-z-index;
  /* 2 */
  box-sizing: border-box;
  width: @notification-width;
  .hook-notification;
}
/* Position modifiers
========================================================================== */

.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: @notification-position;
}
.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: (@notification-width / -2);
}
.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: @notification-position;
}
/* Responsiveness
========================================================================== */

/* Phones portrait and smaller */
@media (max-width: @breakpoint-xsmall-max) {

  .uk-notification {
    left: @notification-position;
    right: @notification-position;
    width: auto;
    margin: 0;
  }

}
/* Message
========================================================================== */

.uk-notification-message {
  position: relative;
  margin-bottom: @notification-message-margin-bottom;
  padding: @notification-message-padding;
  background: @notification-message-background;
  color: @notification-message-color;
  font-size: @notification-message-font-size;
  line-height: @notification-message-line-height;
  cursor: pointer;
  .hook-notification-message;
}
/* Close
 * Adopts `uk-close`
 ========================================================================== */

.uk-notification-close {
  display: none;
  position: absolute;
  top: @notification-close-top;
  right: @notification-close-right;
  .hook-notification-close;
}
.uk-notification-message:hover .uk-notification-close { display: block; }
/* Style modifiers
 ========================================================================== */

/*
 * Primary
 */

.uk-notification-message-primary {
  color: @notification-message-primary-color;
  .hook-notification-message-primary;
}
/*
 * Success
 */

.uk-notification-message-success {
  color: @notification-message-success-color;
  .hook-notification-message-success;
}
/*
 * Warning
 */

.uk-notification-message-warning {
  color: @notification-message-warning-color;
  .hook-notification-message-warning;
}
/*
 * Danger
 */

.uk-notification-message-danger {
  color: @notification-message-danger-color;
  .hook-notification-message-danger;
}
// Hooks
// ========================================================================

.hook-notification-misc;

.hook-notification() {}
.hook-notification-message() {
  border-radius: 3px;
  box-shadow: 0 0 15px fade(@global-color, 20%);
}
.hook-notification-close() {}
.hook-notification-message-primary() {}
.hook-notification-message-success() {}
.hook-notification-message-warning() {}
.hook-notification-message-danger() {}
.hook-notification-misc() {}
